import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact"/>
      <div className="w-full min-h-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto font-['Inter'] mb-20">
      <div className="grid grid-cols-12 gap-0 ">
          <div className="col-span-12 mt-20 md:mt-28 flex justify-center items-center p-4">
               <div className="w-10/12 md:w-12/12 text-4xl md:text-4xl xl:text-5xl text-center font-medium text-[#08408F] tracking-tight">
              More Ways To Contact Us
               </div>
           </div>

           <div className="col-span-12 md:col-span-5 flex justify-start mt-20 p-4 md:p-8 h-auto ">
               <div className="my-10 saturate-50 backdrop-brightness-200 drop-shadow-lg">
               <StaticImage src="../images/office.png" alt="Profile Ramesh" width={800} />
               </div>
           </div>
        

           <div className="col-span-12 md:col-span-7 flex justify-center items-center ">
               <div className="w-full p-4 md:w-12/12 mx-auto text-lg font-medium tracking-tighter text-[#08408F]">
                  <div className="mb-5">
                    We are based out of San Diego, California but we are distributed.
                  </div>
                  <div className="grid grid-cols-12 gap-8 text-sm text-[#08408F]">
                    <div className="col-span-3 flex justify-center items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                      </svg>

                    </div>
                    <div className="col-span-9 text-md flex justify-center items-center font-medium tracking-normal">
                       <div> +1 858-209-7797</div>
                    </div>
                    <div className="col-span-3 flex justify-center items-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                    </svg>

                    </div>
                    <div className="col-span-9 text-md flex justify-center items-center font-medium tracking-normal">
                      hi@websbite.com
                    </div>
                   
                  </div>
                   
                </div>
           </div>
      </div>
      <div className="col-span-12 md:col-span-12 mt-10 text-slate-900 md:mt-2 flex justify-center items-center">
            
            <div className="w-10/12 md:w-6/12 mx-auto mt-2 font-xs flex justify-center items-center tracking-tight">
               <div>
                 Other locations we work from are Hyderabad and Pune in India. 
               </div>
             </div>
             
        </div>

      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Contact , San Diego" />

export default ContactPage


